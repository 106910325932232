/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 */
import React from 'react'
import { graphql } from 'gatsby'
// import i18n from 'i18next'
// conf, action
// components
// containers
import LayoutContainer from '@containers/LayoutContainer'
import TextBasePageContainer from '@containers/TextBasePageContainer'
// helper
// style, img, elm
// const
// const escapeStringRegexp = require("escape-string-regexp")
// const lang = i18n.languages[0]

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 */
const Faq = ({data}) => {

  	return  <LayoutContainer>
    			<TextBasePageContainer className="faq" title="faq">
    				{ data && <div dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }} />}
    			</TextBasePageContainer>
  			</LayoutContainer>
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 */
export default Faq

/**
 * export
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
export const query = graphql`
	query FaqQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(\/ja\/faq)/"}}) {
	    edges {
	      node {
	        id
	        html
	      }
	    }
	  }
	}
`
/*
: todo
export const query = graphql`
	query MyQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/${escapeStringRegexp(pagePath)}/"}}) {
	    edges {
	      node {
	        id
	        html
	        fileAbsolutePath
	      }
	    }
	  }
	}
`
*/